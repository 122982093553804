.EventDetails_container{
    width: 100%;
    height: auto;
    // border: 1px solid gray;
    box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
    border-radius: 20px;
    display: flex;
}
.EventDetails_post{
    width: 45%;
    height: auto;
    // border: 1px solid gray;
}
.registration-inquiry-center{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EventDetails_content {
    width: 55%;
    height: auto;
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    min-height: 100vh; 
    position: relative; 
  }
.img_containerev {
    width: 100%;
    height: 100%; 
    
   
}
.spaceing{
    margin-top: 10px;
}
.registration-inquiry {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 320px;
    background: #fff;
    margin-top: auto; 
    margin-bottom: 15px; 
 
    .side-heading {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 12px;
    }
  
    .contact-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0;
  
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: none;
        color: #333;
        font-size: 1rem;
        transition: 0.3s;
  
        &:hover {
          color: #007bff;
        }
  
        .icon {
          font-size: 1.4rem;
        }
  
        .whatsapp {
          color: #25D366;
        }
  
        .email {
          color: #EA4335;
        }
      }
    }
  }
  
  @media (max-width: 768px) {

    .EventDetails_container{
        width: 100%;
        height: auto;
        // border: 1px solid gray;
        box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
        border-radius: 20px;
        display: block;
    }
    .EventDetails_post{
        width: 100%;
        height: auto;
        // border: 1px solid gray;
    }
    
    .EventDetails_content {
        width: 100%;
        height: auto;
        padding-top: 5px;
        padding-right: 25px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
        min-height: 100vh; 
        position: relative; 
      }

  }
  .cs_download_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center; // Ensures proper alignment
    background-color: rgb(64, 191, 187);
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 24px;
    transition: background-color 0.3s ease;
    margin: 0; // Remove unwanted margin
    text-align: center; 
    white-space: nowrap; // Prevents text wrapping
    gap: 8px; // Ensures space between text and icon without extra gaps
    
    &:hover {
      background-color: rgb(7, 146, 71); 
      color: #fff; 
    }
  
    .cs_download_icon {
      width: 16px;
      height: 16px;
      transition: transform 0.3s ease;
    }
  
    &:hover .cs_download_icon {
      transform: translateY(4px);
    }
  }
  