* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.flex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-one {
    padding: 0 65px;
    width: 100%;
    background-color: gray;
    color: white;
    height: 40px;
    position: fixed !important;
    width: 100%;
    z-index: 999;
}
.left>div+div {
    margin-left: 30px;
}
.right>div+div {
    margin-left: 30px;
}
.email a {
    margin-left: 6px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}
.call a {
    text-decoration: none;
    margin-left: 6px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}
.logins span,
.oneclick a {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}
.email a:hover,
.call a:hover,
.logins:hover,
.oneclick a:hover {
    color: black;
    transition: all 0.5s;
}
.lan_btn {
    select {
      border: none; 
      outline: none; 
      background-color: gray;
      color: white;
      padding-left: 16px;
      padding-right: 7px;
    //   padding: 10p; // Adds padding for a button feel
      border-radius: 4px; 
      font-size: 16px;
      cursor: pointer; 
    //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
    }
  }
  
@media (max-width: 750px) {


    .flex {
        display: block;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-one {
        padding: 0 10px;
        width: 100%;
        background-color: gray;
        color: white;
        height: 50px;
        position: fixed !important;
        width: 100%;
        z-index: 999;
    }
    .email{
        display: none;
    }
    .left>div+div {
        display: none;
    }
    .right>div+div {
        margin-left: 20px;
    }
    .left{
        display: none;
    }
}
@media (max-width: 300px) {
    .navbar-one {
        padding: 0 7px;
        width: 100%;
        background-color: gray;
        color: white;
        height: 50px;
        position: fixed !important;
        width: 100%;
        z-index: 999;
    }
    .flex{
        font-size: 10px;
    }
}

.profil-picture{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

 
.profile-container {
    position: relative;
    cursor: pointer;

    .profile-modal {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 1000000;
        width: 150px;

        .profile-details {
            margin-bottom: 10px;
            .heading{
                font-weight: bold;
            }

            p {
                margin: 5px 0;
                color: black;
            }
        }

        button {
            background-color: #ff4d4d;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #ff1a1a;
            }
        }
    }
}