.container_login{
    width: 100%;
    height: 630px;
    // border: 1px solid black;
    display: flex;
    border-radius: 30px;
    background-image: url(../../../public/images/home/login_img1.png);
    background-size: cover;
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    background-position: center;
}
.left_container{
    width: 50%;
    height: 600px;
    // border: 1px solid black;

}
.right_container{
    width: 50%;
    height: 600px;
    // border: 1px solid black;
}

.center_div{
    position: absolute;
    // width: 450px;
    height:550px;
    //  border: 1px solid black; 
    /* padding: 30px; */
    // margin:0px;
    margin-top: 25px;
    margin-left: 70px;
}
.center_div h1{
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    margin-bottom: 20px;
    margin-left: 25px;
}
.center_div .wel{
    color: #807777;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    letter-spacing: 4px;
    margin-left: 25px;

}
.remember{
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
}
.forgot_password1{
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
}

  
  .input-group {
    margin-bottom: 25px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .options label {
    margin-right: 10px;
  }
  
  .options p {
    margin: 0;
    font-size: 18px;
  }
  
  .center_div button{
    background-color: rgb(115, 114, 116);
  }
  .btn {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    //  background-color: #bd4848;
    display: block;
    width: 110px;
    padding: 10px;
    background-color:black;
    letter-spacing: 1px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .switch-user select {
    margin-bottom: 25px;
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .Dont{
    margin-top: 10px;
  }
  .Or{
    text-align: center;
  }
   @media screen and (max-width: 600px) {
    .container_login{
      width: 100%;
      height: 600px;
      // border: 1px solid black;
      display: flex;
      border-radius: 30px;
      background-image: url(../../../public/images/home/mobile_login_bg.jpg);
      background-size: cover;
      background-repeat: no-repeat; 
      background-position: center;
  }
    .left_container{
        width: 0%;
        height: 600px;
        // border: 1px solid black;
    
    }
    .right_container{
        width: 100%;
        height: 600px;
        // border: 1px solid black;
    }
    .center_div{
        position: absolute;
        // width: 450px;
        height:500px;
        /* border: 1px solid black; */
        /* padding: 30px; */
        margin:50px;
    }
    .input-group input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
    }
    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      color: white;
    }
    .center_div h1{
      font-family: "Barlow", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 32px;
      margin-bottom: 20px;
      margin-left: 25px;
      color: wheat;
  }
  .account{
    color: #fff;
  }
  } 
