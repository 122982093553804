.search-section {

    width: 100%;
    height: 550px;
    margin-top: 70px;
    background-image: url('../../../public/images/home/kidneyNeeds_Search_BG.png'); /* Your background image URL */
    // border: 1px solid black;
    // background-color: aliceblue;
    background-size: cover;  // Ensures the image fully covers the section
    background-position: center; 
    background-repeat: no-repeat; 
    // background-attachment: fixed; 
  
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-container{
    width: 80%;
    // height: 70px;
    // border: 1px solid black;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
  }
  .search_len {
    width: 100% !important; // Makes the input field 90% of the container
    // background-color: aqua;


  }
  .search_len {
    .MuiOutlinedInput-root {
      border-radius: 20px; // Set border radius to 20px
      box-shadow: none; // Disable any default box shadow
      border-radius: 30px; // Adds rounded corners
      background-color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Add box-shadow on hover
      

      &:hover {
      
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Add box-shadow on hover
      }
  
      &.Mui-focused {
      
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Maintain box-shadow when focused
      }
  
     
    }
  }
  
 
  // .rd{
  //   border-radius: 30px;
  // }
  // .clickable {
  //   cursor: pointer;
  // }
  // .mr{
  //   margin-top: 700pc;
  // }
  // .non{
  //   display: none;
  // }