.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}

.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}

.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}

.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}

.cs_video_open {
  cursor: pointer;
}

.cs_hero.cs_style_1 {

  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}

.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}

.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}

.cs_rating {
  i {
    display: flex;
  }
}

.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}

.cs_cs_slider_navigation_1 {

  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;

    @media (min-width: 991px) {
      opacity: 0;
    }
  }

  .cs_slider_next {
    right: -35px;
  }

  .cs_slider_prev {
    left: -35px;
  }

  &:hover {

    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}

.cs_cs_slider_navigation_2 {

  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }

  .cs_slider_next {
    right: 0;
  }

  .cs_slider_prev {
    right: 52px;
  }
}

.cs_cs_slider_navigation_3 {

  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;

    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }

    @media (max-width: 991px) {
      display: none !important;
    }

    img {
      transition: inherit;
    }

    &:hover {
      background-color: $accent;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .cs_slider_next {
    right: 0;
  }

  .cs_slider_prev {
    right: 115px;

    @media (max-width: 1700px) {
      right: 100px;
    }
  }

  .slick-dots {
    display: none !important;

    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}

.cs_contact_us_slider {
  padding-bottom: 0 !important;

  .slick-dots {
    left: 50%;
    bottom: 20px;
  }

  .cs_slider_prev,
  .cs_slider_next {
    display: none !important;
  }
}

.cs_related_doctor {
  padding-bottom: 90px;

  @media (max-width: 400px) {
    padding-bottom: 75px;
  }

  .slick-dots {
    bottom: 15px;

    li {
      background-color: #fff;
      border: 2px solid #fff;
    }
  }
}

.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);

    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .cs_section_heading.cs_style_1 {
    min-height: 263px;

    @media (max-width: 1700px) {
      min-height: 166px;
    }

    @media (max-width: 991px) {
      min-height: initial;
    }
  }

  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }

    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;

        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }

        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }

      @media (max-width: 991px) {
        grid-column: span 2;
      }

      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }

  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}

.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }

  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }

  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}

.cs_portfolio.cs_style_1 .cs_portfolio_img>div>img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }

  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}

.cs_pagination_box {

  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;

    &:hover {
      color: $accent;
    }
  }

  .cs_pagination_arrow {
    background-color: transparent;
  }
}

.form-check .form-check-input {
  border-color: #636363;
}

.cs_related_doctor .slick-dots {
  display: flex !important;
}

.react-datepicker-wrapper {
  display: block;
}

.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }

  @media (max-width: 1400px) {
    grid-gap: 20px;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 992px) {
    >* {
      grid-column: span 2;

      &:first-child {
        grid-row: span 3;
      }
    }
  }
}

.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}

.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active+ul {
  display: block;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.kidney-education-subtitle {
  color: #333333 !important;
}

.kidney-our-service-subtitle {
  color: #333333 !important;
}

.pop-up-contact {
  padding: 20px !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .cs_healthy_records .cs_banner.cs_style_6 .cs_banner_img {
    bottom: 0;
  }
}


.bright-blogs {
  .cs_post {
    .cs_post_title {
      color: #fff;
    }

    p,
    .cs_post_btn {
      color: antiquewhite;
    }
  }
}

.cs_post {
  // padding: 20px;
  border-radius: 8px;
  // color: #fff;

  .cs_download_btn {
    display: inline-flex;
    align-items: center;
    background-color: rgb(64, 191, 187);
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 24px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  // Ensures text inside the button is centered
    padding: 10px 20px;  // Optional: adjust padding if needed
    // background-color: #7a7a7a; // Adjust background color if needed
    color: white;
    text-decoration: none;

    &:hover {
      background-color: rgb(7, 146, 71); 
      color: #fff; 
    }
    

    .cs_download_icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      transition: transform 0.3s ease;
    }

    &:hover .cs_download_icon {
      transform: translateY(4px); // Slight movement on hover for a subtle effect
    }
  }
  .cs_download_btn {
    display: inline-flex;
    align-items: center;
    background-color: rgb(64, 191, 187);
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 24px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;  // Ensures text inside the button is centered
    padding: 10px 20px;  // Optional: adjust padding if needed
    // background-color: #7a7a7a; // Adjust background color if needed
    color: white;
    text-decoration: none;

    &:hover {
      background-color: rgb(7, 146, 71); 
      color: #fff; 
    }
    

    .cs_read_icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      transition: transform 0.3s ease;
    }

    &:hover .cs_read_icon {
      transform: translateX(4px);; // Slight movement on hover for a subtle effect
    }
  }
}

.color{
  color: darkgrey;
}
