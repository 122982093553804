//  .quotes_container{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: red;
//   }
  .custom-list {
    margin: 0;
    padding-left: 20px; 
    font-size: 18px;
    line-height: 1.2;
  }
  .custom-list-item {
    margin: 0;
    padding: 0;
  }
  .textcolor{
    color: rgb(18, 104, 47);
  }
  .textcolorceo{
    color: rgb(58, 56, 56);
  }
  .tablettxt{
    color: rgb(221, 221, 221);
  }
  .Ponooguntxt{
    font-size: 18px;
  }
  .Ponooguntxt span{
    font-weight: bold;
  }

  .ceomessage{
    font-style: italic;
  }
  .message_from_ceo_container p{
    font-size: 18px;
  }
//   .message_from_ceo_container h2{
//     font-size: 26px;
//   }

.Announcement_details{
    display: flex;

}
.Aleft_container{
    // border: 1px solid black;
    width: 69%;
}
.Aright_container{
    // border: 1px solid yellow;
    width: 300px; /* Set a fixed width */
    height: auto;
}
.announcement_width h4{
    // text-decoration: none;
    color: white;
}
.message_from_ceo_container p span{
  font-weight: bold;
}
.message_from_ceo_container h4 span{
  font-weight: bold;
}
.message_from_ceo_container ul li span{
  font-weight: bold;
}
.lists_s ul li span{
  font-weight: bold;
}
  

/* SCSS Styles */
.Aright_container {
    width: 300px; /* Set a fixed width */
    height: auto; /* Adjust based on your content */
  }
  
  .fixedd {
    position: static;
    width: 100%; /* Maintain the same width as its parent */
    // transition: all 0.3s ease-in-out;
  }
  
  .fixedd.fixed {
    position: fixed;
    top: 180px;
    width: 300px; /* Match the width of the original container */
  }
  
.containeran{
    border-radius: 40px;
}
.highlighted-number {
    color: #090786; // Example color
    font-weight: bold;
    // text-decoration: underline; // Optional
  }

// Lunakidney
.lunabyenow{
    display: flex;
    align-items: center;
    justify-content: center;
}
.lunabyenow_in{
    // border: 1px solid black;
    padding: 17px;
    width: 27%;
    border-radius: 6px;
    // background-color: #15860b;
    // background: linear-gradient(180deg, #75c46e, #25c717, #11580e);
    background: linear-gradient(45deg, #760892, #8f0594,#6a11cb);
    border-radius: 8px;
    -webkit-box-shadow:0px 0px 16px 0px rgba(184,178,178,0.93);
    -moz-box-shadow: 0px 0px 16px 0px rgba(184,178,178,0.93);
    box-shadow: 0px 0px 16px 0px rgba(184,178,178,0.93);
}
.lunabyenow_in h4{
    color: #fff;
}
.lunatxt{
    color: #fff;
}
  .lunakidneytxt{
    color: yellow;
  }
  .luna h4{
    color: #fff;
  }

  @media (max-width: 700px) {
    .Announcement_details {
      display: block; /* Change layout for smaller screens */
    }
  
    .Aleft_container {
      width: 100%; /* Full width on smaller screens */
    }
  
    .Aright_container {
      width: 100%; /* Full width on smaller screens */
      margin-top: 20px; /* Add spacing below the left container */
    }
   .cs_sidebar{
    width: 250px;
    margin-left: 10px;
    padding: 5px;
   }
      .announcement_width{
        width: 180px;
        // margin-left: 10px;
      }
      .announcement_width h2{
        font-size: 18px;
        margin: 0;
      }
      .announcement_width h4{
        font-size: 14px;
        margin: 0;
      }
      .lunabyenow_in{
        border: 1px solid black;
        padding: 17px;
        width: 60%;
    }
    .cs_sidebar_item{
          background: linear-gradient(180deg, #75c46e, #25c717, #11580e);

    }
    .mobile_space{
      margin-top: 15px;
    }
  }