/*--------------------------------------------------------------
20. About
----------------------------------------------------------------*/
.cs_about.cs_style_1 {
  .cs_about_img {
    position: relative;
    @media (min-width: 992px) {
      padding-right: 70px;
    }
  }
  .cs_about_text_list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 60px;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
      > i {
        position: absolute;
        left: 0;
        top: 5px;
        @media (max-width: 991px) {
          top: 2px;
        }
      }
      > h3 {
        margin-bottom: 25px;
      }
    }
  }
  .cs_about_mini_img {
    position: absolute;
    top: -75px;
    right: 20%;
  }
  .cs_right_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cs_rotate_animation {
    animation: rotate360 15s linear infinite;
  }
  &.cs_type_1 {
    .cs_about_mini_img {
      right: initial;
      top: 44%;
      left: 35%;
    }
    br {
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }
  &.cs_type_2 {
    padding-left: 102px;
    @media (max-width: 1700px) {
      padding-left: 50px;
    }
    @media (max-width: 1400px) {
      padding-left: 0px;
    }
    .cs_about_mini_img {
      top: 50%;
      right: initial;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cs_about_img {
      padding-right: 0;
      position: relative;
    }
  }
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cs_about.cs_style_2 {
  .cs_about_img {
    padding-left: 80px;
    @media (max-width: 1700px) {
      padding-left: 0;
    }
  }
  .cs_section_heading.cs_style_1 {
    p {
      max-width: 580px;
    }
  }
}
.cs_about.cs_style_3 {
  background: linear-gradient(119deg, #d2eaef 17.16%, #86bbf1 89.78%);
  .cs_section_heading.cs_style_1 {
    padding: 145px 80px;
    @media (max-width: 1400px) {
      padding: 145px 30px 145px 50px;
    }
    @media (max-width: 1199px) {
      padding: 50px 50px 50px 50px;
    }
    @media (max-width: 767px) {
      padding: 35px 24px 35px 24px;
    }
  }
  .cs_about_text {
    height: 100%;
    @media (max-width: 1199px) {
      height: initial;
    }
  }
  .cs_about_img {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cs_section_minititle {
    max-width: 630px;
  }
  .cs_support_card {
    border-radius: 50px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
    padding: 20px 30px;
    display: flex;
    align-items: center;
    gap: 14px;
    position: absolute;
    right: -26%;
    bottom: 75px;
    .cs_support_card_icon {
      flex: none;
    }
    @media (max-width: 1700px) {
      right: -34%;
      bottom: 65px;
    }
    @media (max-width: 1199px) {
      position: initial;
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 35px;
        padding: 20px 20px;
      }
    }
  }
}

.cs_iconbox.cs_style_111 {
  display: flex;
  align-items: center;
  background-color: rgba(107, 222, 245, 0.5);
  // padding: 38px 54px;
  gap: 30px;
  width: 330px;
  border-radius: 10px;
  @media (max-width: 1700px) {
    padding: 17px;
    gap: 20px;
  }
  @media (max-width: 380px) {
    padding: 25px 20px;
    gap: 15px;
  }
  @media (max-width: 330px) {
    padding: 20px 20px;
    gap: 10px;
  }
  .cs_iconbox_icon {
    flex: none;
    @media (max-width: 1700px) {
      max-width: 60px;
    }
    @media (max-width: 380px) {
      max-width: 40px;
    }
    @media (max-width: 330px) {
      max-width: 35px;
    }
  }
  
  .cs_iconbox_right {
    flex: 1;
  }
}






.bg_color_about{
  background-color: rgb(236, 234, 234);
}

.About_container{
  padding-left: 15px;
  padding-right: 15px;
  // background-color: rgb(236, 234, 234);
}
.About_container_border{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

}
.cs_banner_tex{
  padding: 10px;
  padding-top: 25px;
}
.about_banner_container{
  // border: 1px solid orangered;
  // width: 100%;
  display: flex;
  justify-content: space-between;
}
.banner_sub_section1{
  width: 60%;
  // border: 1px solid black;
}
.banner_sub_section2{
  width: 20%;
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Abptextgap{
  margin-top: 20px;
}
.card_box1_content2 h6{
  color: black;
}
.banner_sub_section3{
  width: 20%;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner_sub_section3_in{
  background-image: url(../../../src/images/diet/About-us-kidneyneeds-intro-video-logo.jpg);
  background-size: cover; /* Ensures the image covers the entire container */
background-position: center; /* Centers the image */
background-repeat: no-repeat;
border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 30px;
  border: 1px solid rgb(248, 244, 244);
  color: #fff;
  // background-color: #d2eaef;
}
.banner_sub_section3_in i{
   color: orangered;
  font-size: 30px;
  cursor: pointer;
}
.about_banner_container2{
  // border: 1px solid orangered;
  // width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.card_containerlist{
  display: flex;
  // justify-content: space-between;
  // justify-content: space-around;
  gap: 20px;
  width: 50%;
  // border: 1px solid yellow;
}
.card_box1{
  width: 30%;
  height: 160px;
  border: 1px solid rgb(248, 244, 244);
  color: #fff;
  border-radius: 10px;
}
.card_box1_content{
  width: 100%;
  height: 75px;
  // border: 1px solid white;
}
.card_box1_content1{
  // border: 1px solid black;
 padding-left: 6px;
 padding-right: 6px;
 padding-top: 4px;
  display: flex;
  justify-content: space-between;
}
.card_box1_content1 h5{
  color: black;
}
.card_box1_content1 h5 i{
  color: green;
}
.card_box1_content2{
  padding-top: 4px;
  text-align: center;
}
.card_box1_image{
  width: 100%;
  height: 85px;
  // border: 1px solid white;
  border-radius: 10px;
  background-image: url(../../../src/images/diet/aboutus-page-kidney-care.jpg);
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
}
.card_box2{
  // background-color: #e7f4fa;
  background-image: url(../../../src/images/diet/Human_AI.png);
  width: 62%;
  // width: 350px;
  padding: 15px;
  height: 160px;
  border: 1px solid rgb(248, 244, 244);
  color: #fff;
  border-radius: 10px;
}
.card_box2_content h5{
  color:#fff;
  font-size: 22px;
  font-weight: 400;

}
// .card_box2_img{
//   border: 1px solid rgb(248, 244, 244);
//   color: #fff;
//   border-radius: 20px;
//   height: 60px;
// }
.content_container{
  // margin-top: 30px;
  border: 1px solid rgb(248, 244, 244);
  color: #fff;
  border-radius: 10px;
  // height: 150px;
  width: 35%;
}
.content_container_ab1{
   padding-top: 10px;
   padding-bottom: 10px;
  // border: 1px solid black;
  text-align: center;
}
.content_container_ab1 h3{
  color: black;
}
.content_container_ab2{
  padding-left: 10px;
  padding-right: 10px;
  // border: 1px solid black;
//  height: 80px;
}
.content_container_ab2 img{
  width: 100%;       /* Ensures the image covers the width of its container */
  height: 100%;      /* Ensures the image covers the height of its container */
  object-fit: cover; /* Makes the image cover its container without distortion */
 
  margin: 0;         /* Ensures no extra spacing around the image */
  display: block; 
}




.About_ai_heading_container{
  text-align: center;
  width: 100%;
  // border: 1px solid black;
}
.About_ai_heading_container p{
  font-size: 22px;
}
.About_ai_flex_container{
  display: flex;
  justify-content: space-between;
}
.About_ai_box{
  width: 31.5%;
  // height: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  padding: 25px;
}
.bgimg-mission{
  background-image: url(../../images/diet/Missionnew.png);
  background-size: cover;
  background-position: center;
 
}
.bgimg-value{
  background-image: url(../../images/diet/value-new.png);
  background-size: cover;
  background-position: center;
 
}
.bgimg-vision{
  background-image: url(../../images/diet/vissionnew.png);
  background-size: cover;
  background-position: center;
 
}
.About_ai_box_in{
  display: flex;
  justify-content: space-between;
}
.About_icon1 img{
  cursor: pointer;
   font-size: 22px;
   margin-top: -10px;
}
.About_icon2 img{
  cursor: pointer;
  font-size: 22px;
  margin-top: -10px;
}
.About_icon3 img{
  cursor: pointer;
  font-size: 22px;
  margin-top: -10px;
}

.green_container{
  border: 1px solid white;
  width: 100%;
  // height: 300px;
  border-radius: 20px;
  padding: 30px;
  background-color: #3176BF;
  display: flex;
  justify-content: center;
  align-items: center;

}
.green_container_text{
  text-align: center;
  color: white;
}
.green_container_text  p{
  width: 75%;
  font-size: 20px;
}
.About_btn{
  margin-top: 15px;
}
.greentext_para{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.cs_btnA.cs_style_1A {
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  font-weight: 400;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  // padding: 6px 10px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 8px;
  outline: none;
  border: none;
  position: relative;
  background: transparent;

  @media (max-width: 991px) {
    // padding: 6px 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 2px;
    
  }

  span {
    position: relative;
    z-index: 2;
  }

  i {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 15px;
    height: 11px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }

  img {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    filter: brightness(0) invert(1);

    &:first-child {
      left: -20px;
    }

    &:last-child {
      left: 0;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }

  &::before {
    // background-image: linear-gradient(134deg, #307bc4 0%, #274760 100%);
    background-image: linear-gradient(134deg,
        rgb(64, 191, 187) 0%,
        rgb(7, 146, 71) 100%); // For home page submit in footer
  }

  &::after {
    // background-image: linear-gradient(270deg, #307bc4 0%, #274760 100%);
    background-image: linear-gradient(270deg,
        rgb(64, 191, 187) 0%,
        rgb(7, 146, 71) 100%); // For home page submit in footer
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }

    img {
      &:first-child {
        left: 0px;
      }

      &:last-child {
        left: 20px;
      }
    }
  }
}

.green_container_text h3{
  color: white;
}



// aboutSlider
.slider-container {
  width: 100%;
  // height: 80vh;
  border-radius: 30px;

  .swiper {
    height: 100%;
    border-radius: 30px;
  }

  .slide {
    border-radius: 30px;
    display: flex;
    height: 100%;

    .slide-content {
      width: 100%;
      padding: 25px;
      padding-left: 35px;
      padding-right: 35px;
      color: white;
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);

      .slide-content_in {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 12px;
        .slide-content_container {
          width: 50%;
          height: 100%;
          padding-left: 15px;
        }
        .slide-content_container p{
          font-size: 22px;
          text-shadow: none;
        }
        .slide-content_container h1{
          text-shadow: none;
        }

        .slide-image_container {
          // border: 1px solid black;
          width: 50%;
          height: 100%;
          padding: 15px;
          display: flex;
        }

        .slide-image_container img {
          margin-left: 120px;
          align-items: center;
          justify-content: center;
          // border: 1px solid black;
          // width: 500px;
          // height: 380px;
          object-fit: cover;
        }
      }


    }
  }

  // Mobile View
  @media (max-width: 768px) {
    height: auto;
    
    .swiper {
      height: auto;
    }

    .slide {
      flex-direction: column;

      .slide-content {
        padding: 15px;

        .slide-content_in {
          flex-direction: column;
          
          .slide-content_container {
            width: 100%;
            padding-left: 40px;
            padding-right: 20px;
            padding-bottom: 15px;
          }

          .slide-image_container {
            width: 100%;
            padding: 20px;

            img {
              margin-left: 60px;
              height: 200px;
               width: 100%; 
               object-fit: cover;

            }
          }
        }

 
      }
    }
    
    .partner_section_top{
      display: block;
      justify-content: space-between;
    }
    .partner_section_top1{
      width: 80%;
      // border: 1px solid black;
    }
    .partner_section_top2{
      width: 80%;
      // border: 1px solid black;
    }
    .partner_section_top21{
      width: 73%;
      // border: 1px solid black;
    }
    .partner_section_top02{
      display: none;
      justify-content: space-between;
    }
    .partner_section_top1 p{
      font-size: 20px;
    
    }
    .partner_section_top2{
      display: block;
      justify-content: space-between;
    }
    .partner_section_top21{
      display: block;
      justify-content: space-between;
    }
    .About_ai_flex_container{
      display: block;
      justify-content: space-between;
    }
  }

  @media (max-width: 480px) {
    .slide-content {
      padding: 10px;

      h1 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }

      .slide-content_in {
        .slide-image_container img {
          height: 200px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about_banner_container{
    // border: 1px solid orangered;
    // width: 100%;
    display: block;
    justify-content: space-between;
  }
  .banner_sub_section1{
    width: 100%;
    // border: 1px solid black;
  }
  .banner_sub_section2{
    width: 100%;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  .banner_sub_section3{
    width: 100%;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about_banner_container2{
    // border: 1px solid orangered;
    // width: 100%;
    display: block;
    justify-content: space-between;
  }
  .card_containerlist{
    display: flex;
    justify-content: space-between;
    width: 100%;
    // border: 1px solid yellow;
  }
  .content_container{
    margin-top: 30px;
    border: 1px solid rgb(248, 244, 244);
    color: #fff;
    border-radius: 10px;
    // height: 150px;
    width: 100%;
  }
  .card_containerlist{
    display: flex;
    // justify-content: space-between;
    // justify-content: space-around;
    gap: 20px;
    width: 100%;
    // border: 1px solid yellow;
  }
  .card_box1{
    width: 40%;
    // height: 160px;
    border: 1px solid rgb(248, 244, 244);
    color: #fff;
    border-radius: 10px;
  }
  .card_box1_content{
    width: 100%;
    height: 75px;
    // border: 1px solid white;
  }
  .card_box1_content1{
    // border: 1px solid black;
   padding-left: 6px;
   padding-right: 6px;
   padding-top: 4px;
    display: flex;
    justify-content: space-between;
  }
  .card_box1_content1 h5{
    color: black;
  }
  .card_box1_content1 h5 i{
    color: green;
  }
  .card_box1_content2{
    padding-top: 4px;
    text-align: center;
  }
  .card_box1_image{
    width: 100%;
    height: 85px;
    // border: 1px solid white;
    border-radius: 10px;
    background-image: url(../../../src/images/diet/aboutus-page-kidney-care.jpg);
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
  }
  .card_box2{
    // background-color: #e7f4fa;
  background-image: url(../../../src/images/diet/Human_AI.png);
  width: 62%;
  // width: 350px;
  padding: 15px;
  height: 160px;
  border: 1px solid rgb(248, 244, 244);
  color: #fff;
  border-radius: 10px;
  }
  .card_box2_content h5{
    color:#fff;
    font-size: 19px;
    font-weight: 400;
  
  }
  // .card_box2_img{
  //   border: 1px solid rgb(248, 244, 244);
  //   color: #fff;
  //   border-radius: 20px;
  //   height: 60px;
  // }
.banner_sub_section3{
  margin-top: 20px;
} 















}

@media (max-width: 700px){
  .About_ai_flex_container{
    display: block;
    gap: 20px;
    justify-content: space-between;
  }
  .About_ai_box{
    width: 100%;
    // height: 300px;
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15px;
  }
}



.partner_section{
  display: flex;

}
.partner_section_left{
  width: 60%;
  height: 350px;
  // border: 1px solid black;
}
.partner_section_left_side1{
  width: 100%;
  height: 200px;
  // border: 1px solid black;

}
.partner_section_left_side2{
  width: 100%;
  height: auto;
  // border: 1px solid black;
}
.partner_section_right{
  width: 40%;
  // height: 300px;
  // border: 1px solid black;
}
.partner_section_right_side1{
  width: 100%;
  height: 180px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
}
.partner_section_right_side1_in{
  display: flex;
  gap: 40px;
}
.partner_section_right_side1_in p{
 margin: 0;
 line-height: 20px;
 font-weight: 400;
 letter-spacing: 1px;
 
}
.partner_section_right_side1_in h3{
  color: white;
}
.partner_section_right_side2{
  width: 100%;
  height: 180px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
}
.partner_section_right_side2_in{
  display: flex;
  margin-left: 75px;
  gap: 40px;
}
.partner_section_right_side2_in p{
  margin: 0;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  
 }
 .partner_section_right_side2_in h3{
   color: white;
 }

.sm_boxNew1{
  width: 140px;
  height: 140px;
  border: 1px solid #336BB8;
  border-radius: 10px;
  padding: 10px;
  background-color: #336BB8;
  color: white;
}
.sm_boxNew2{
  width: 140px;
  height: 140px;
  border: 1px solid #FF563D;
  border-radius: 10px;
  padding: 10px;
  background-color: #FF563D;
  color: white;
}
.sm_boxNew3{
  width: 140px;
  height: 140px;
  border: 1px solid #FE116B;
  border-radius: 10px;
  padding: 10px;
  background-color: #FE116B;
  color: white;
}
.sm_boxNew4{
  width: 140px;
  height: 140px;
  border: 1px solid #0A9F62;
  border-radius: 10px;
  padding: 10px;
  background-color: #0A9F62;
  color: white;
}
@media (max-width: 768px) {
  
.partner_section{
  display: block;

}
.partner_section_left{
  width: 100%;
  // height: 170px;
  // border: 1px solid black;
}
.partner_section_left_side1{
  width: 100%;
  // height: 70px;
  // border: 1px solid black;

}
.partner_section_left_side2{
  width: 100%;
  height: 100px;
  // border: 1px solid black;
}
.partner_section_right{
  width: 100%;
  // height: 300px;
  // border: 1px solid black;
}
.partner_section_right_side1{
  width: 100%;
  // height: 150px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
}
.partner_section_right_side1_in{
  display: flex;
  
  gap: 30px;
}
.partner_section_right_side2{
  width: 100%;
  // height: 150px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
}
.partner_section_right_side2_in{
  display: flex;
  margin-left: 0;
  gap: 30px;
}

}


.cs_btnAb.cs_style_1Ab {
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  font-weight: 400;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  outline: none;
  border: none;
  position: relative;
  background: transparent;

  @media (max-width: 991px) {
    padding: 6px 10px;
  }

  span {
    position: relative;
    z-index: 2;
  }

  i {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 15px;
    height: 11px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }

  img {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    filter: brightness(0) invert(1);

    &:first-child {
      left: -20px;
    }

    &:last-child {
      left: 0;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }

  &::before {
    // background-image: linear-gradient(134deg, #307bc4 0%, #274760 100%);
    background-image: linear-gradient(134deg,
        rgb(64, 191, 187) 0%,
        rgb(7, 146, 71) 100%); // For home page submit in footer
  }

  &::after {
    // background-image: linear-gradient(270deg, #307bc4 0%, #274760 100%);
    background-image: linear-gradient(270deg,
        rgb(64, 191, 187) 0%,
        rgb(7, 146, 71) 100%); // For home page submit in footer
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }

    img {
      &:first-child {
        left: 0px;
      }

      &:last-child {
        left: 20px;
      }
    }
  }
}


.About_founder_container{
  // border: 1px solid black;
  width: 100%;
  // height: 300px;
  display: flex;
}
.About_founder_container1{
  // border: 1px solid black;
  width: 40%;
  padding: 15px;
  // height: 300px;
}
.About_founder_container11{
  // border: 1px solid black;
  width: 40%;
  padding: 15px;
  margin-left: 40px;
  // height: 300px;
}
.About_founder_container2{
  // border: 1px solid black;
  width: 70%;
  // padding: 30px;
 padding-left: 30px;
 padding-top: 30px;
 padding-bottom: 30px;
  // height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.About_founder_container21{
  // border: 1px solid black;
  width: 70%;
  // padding: 30px;
//  padding-left: 30px;
 padding-top: 30px;
 padding-bottom: 30px;
  // height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.About_founder_container2_in{
  background-color: #fff;
  padding: 25px;
  border: 1px solid rgb(245, 245, 245);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

@media (max-width: 768px) {
  .About_founder_container{
    // border: 1px solid black;
    width: 100%;
    // height: 300px;
    display: block;
  }
  .About_founder_container1{
    // border: 1px solid black;
    width: 100%;
    padding: 15px;
    // height: 300px;
  }
  .About_founder_container11{
    // border: 1px solid black;
    width: 100%;
    padding: 15px;
    margin-left: 0;
    // height: 300px;
  }
  .About_founder_container2{
    // border: 1px solid black;
    width: 100%;
    // padding: 30px;
   padding-left: 0;
   padding-top: 30px;
   padding-bottom: 30px;
    // height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .About_founder_container21{
    // border: 1px solid black;
    width: 100%;
    // padding: 30px;
   padding-left: 0;
   padding-top: 30px;
   padding-bottom: 30px;
    // height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.product_title_color{
  color: black;
}
.productcolor{
  color: black;
}
.button-containe {
  margin-top: 8px;
  // display: flex;
  // justify-content: center;
}

.custom-button {
  display: inline-block;
  padding: 6px 14px;
  background-color: #3C78BD;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.custom-button:hover {
  background-color: rgb(17, 65, 41);
}


.cs_post_info_product {
  padding: 20px 30px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding: 30px 25px 30px;
  }

  p {
    opacity: 0.7;
  }
}
.mr_top{
  margin-top: 8px;
}



// one click solution 

.oneClickSolution_container{
  // border: 1px solid black;
  width: 100%;
  // height: 90px;
}
.oneClickSolution_con{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  // width: 100%;
padding: 15px;
  display: flex;
 
}
.oneClickSolution_con_content{
  width: 50%;
  height: 300px;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}


.oneClickSolution_con_image{
  width: 50%;
  height: 300px;
  // border: 1px solid black;
  border-radius: 20px;
  background-image: url(../../images/diet/one_click.png);
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

}

@media (max-width: 768px) {
  .oneClickSolution_con{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    // width: 100%;
  padding: 15px;
    display: block;
   
  }
  .oneClickSolution_con_content{
    width: 100%;
    height: 300px;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  
  
  .oneClickSolution_con_image{
    width: 100%;
    height: 300px;
    // border: 1px solid black;
    border-radius: 20px;
    background-image: url(../../images/diet/one_click.png);
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
  }
}

.bgcolor{
  background-color: #fff;
  border-radius: 20px;
}
.OurInitiatives_containers {
 
  // border: 1px solid black;
  width: 100%;
  // height: 400px;
  display: flex;
  gap: 25px;
  
}
.OurInitiatives_containers1,
.OurInitiatives_containers2{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
  width: 50%;
  // height: 300px;
  padding: 17px;
  // border: 1px solid black;
}

.OurInitiatives_containers1 img{
  width: 100%;
  height: 180px;
  border-radius: 25px;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.OurInitiatives_containers2 img{
  width: 100%;
  height: 180px;
  border-radius: 25px;
}
@media (max-width: 768px) {
  .OurInitiatives_containers {
 
    // border: 1px solid black;
    width: 100%;
    // height: 400px;
    display: block;
    gap: 25px;
    
  }
  .OurInitiatives_containers1,
.OurInitiatives_containers2{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
  width: 100%;
  // height: 300px;
  padding: 17px;
  margin-top: 15px;
  // border: 1px solid black;
}
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.icon {
  font-size: 30px;
  margin-top: -10px;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.title {
  font-weight: bold;
}

 .subtitle {
  font-size: 15px;
  color: gray;
}
.spacing_about{
  margin-top: 50px;
}
.text-right {
  text-align: right;
}
.swiper-button-next,
.swiper-button-prev {
  color: #3f3938 !important; 
    font-size: 40px;
  font-weight: 900; // Extra bold
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

/* Ensure better visibility */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 40px;
  font-weight: 900; // Ensures boldness
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); // Adds depth
}

.aboutimpact_container{
  display: flex;
  justify-content: space-around;
}
.widthab{
  width: 47%;
}
.historyimg img{
  border-radius: 15px;
}
.WhyKidneyNeeds_container{
  background-color: #fff;
  padding: 30px;
  border: 1px solid rgb(245, 245, 245);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0px 4px 10px rgba(2, 241, 94, 0.1);
  // border: 1px solid greenyellow;
}
.CoreValues_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px; // Adds spacing between items...
  width: 100%;
  // border: 1px solid black;
  padding: 20px;
  margin-top: 15px;
}

.cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  background-color: #fff;

}

@media (max-width: 768px) {

  .aboutimpact_container{
    display: block;
    // gap: 20px;
    // justify-content: space-around;
  }
  .widthab{
    width: 100%;
  }
  .abgap{
    margin-top: 10px;
  }
  .CoreValues_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px; // Adds spacing between items...
    width: 100%;
    // border: 1px solid black;
    padding: 20px;
    margin-top: 15px;
  }
}

.eventaligin{
  display: flex;
  justify-content: center;
  align-items: center;
}

.DownloadPoster_container{
  display: flex;
  justify-content: center;
  align-items: center;
}