.advertisement-bot {
    width: 250px;
    height: 400px;
    position: fixed;
    bottom: 40px; // Adjust for desired placement
    right: 20px;  // Adjust for desired placement
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background: none;
      border: none;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  
    .ad-image {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      border-radius: 6px;
    }
  
    .ad-text {
      text-align: center;
      h4 {
        margin: 0;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        color: #555;
      }
    }
  }
  